.icon-nav{
    padding: 6px;
    transition: 0.3s;
    color: #606060;
}
.icon-nav:hover{
    transition: 0.3s;
    color: #363B64;
}
.icon-nav:active{
    border-radius: 50%;
    padding: 8px;
    transition: 0.3s;
    color: #363B64;
    background:  rgba(0, 0, 0, 0.16);
}
.icon-nav-img{
    border-radius: 50%;
    width: 35px ;
    height: 35px ;
    object-fit: cover;
}
.icon-nav-img-lg{
    border-radius: 50%;
    width: 85px ;
    height: 85px ;
    object-fit: cover;
}



.side-nav-open {
    padding-top: 70px;
    height: 100%;
    width: 240px;
    position: fixed;
    top: 0;
    left: 0;
    overflow-x: hidden;
    transition: 0.3s;
    background-color: #ffffff;
    border-right: 1px solid rgba(0,0,0,.075);
    scrollbar-color: #CCCCCC #F5F5F5;
    scrollbar-width: thin;
}
.side-nav-close {
    padding-top: 70px;
    height: 100%;
    width: 0;
    position: fixed;
    top: 0;
    left: 0;
    overflow-x: hidden;
    transition: 0.3s;
    background-color: #ffffff;
    border-right: 1px solid rgba(0,0,0,.075);
    scrollbar-color: #CCCCCC #F5F5F5;
    scrollbar-width: thin;
}
.side-nav-open::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}
.side-nav-open::-webkit-scrollbar-track-piece  {
    background-color: #F5F5F5;
}
.side-nav-open::-webkit-scrollbar-thumb:vertical {
    height: 30px;
    border-radius: 4px;
    background: #CCCCCC;
}
.side-nav-close::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}
.side-nav-close::-webkit-scrollbar-track-piece  {
    background-color: #F5F5F5;
}
.side-nav-close::-webkit-scrollbar-thumb:vertical {
    height: 30px;
    border-radius: 4px;
    background: #CCCCCC;
}




.content {
    transition: 0.3s;
    margin-top: 80px;
    margin-left: 240px;
}
.content-expand {
    margin-top: 80px;
    transition: 0.3s;
    margin-left: 0;
}



.nav-logo {
    width: auto;
    height: 30px;
}


.side-bar-item{
    display: inline-block;
    padding: 12px 5px 12px 5px;
    width: 100% !important;
}
.side-bar-item:hover{
    border-left: 4px solid #CB0C9F;
    transition: 0.2s;
    background-color: #F3D9EC;
}
.side-bar-item:hover .side-bar-item-icon {
    color: #363B64;
}
.side-bar-item:hover .side-bar-item-caption {
    color: #363B64;
}
.side-bar-item-active{
    border-left: 4px solid #CB0C9F;
    background-color:#F3D9EC;
}
.side-bar-item-icon{
    font-size: 18px;
    margin-left: 10px;
    margin-right: 10px;
    font-weight: 500;
    color: #363B64;
}
.side-bar-item-caption{
    font-family: 'Poppins', sans-serif ;
    font-size: 13px;
    margin-left: 5px;
    font-weight: 500;
    color: #363B64;
}


/* Responsive */

@media (max-width: 339.98px) {
    .side-nav-open {
        width: 0;
        position: fixed;
        top: 0;
        left: 0;
        overflow-x: hidden;
    }
    .side-nav-close {
        width: 180px;
        position: fixed;
        top: 0;
        left: 0;
        overflow-x: hidden;
    }
    .content {
        width: 100%;
        margin-left: 0;
    }
    .content-expand {
        width: 100%;
        margin-left: 180px;
    }
}

@media (min-width:340px) and (max-width: 360.98px) {
    .side-nav-open {
        width: 0;
        position: fixed;
        top: 0;
        left: 0;
        overflow-x: hidden;
    }
    .side-nav-close {
        width: 180px;
        position: fixed;
        top: 0;
        left: 0;
        overflow-x: hidden;
    }
    .content {
        width: 100%;
        margin-left: 0;
    }
    .content-expand {
        width: 100%;
        margin-left: 180px;
    }
}

@media (min-width: 361px) and (max-width: 575.98px) {
    .side-nav-open {
        width: 0;
        position: fixed;
        top: 0;
        left: 0;
        overflow-x: hidden;
    }
    .side-nav-close {
        width: 180px;
        position: fixed;
        top: 0;
        left: 0;
        overflow-x: hidden;
    }
    .content {
        width: 100%;
        margin-left: 0;
    }
    .content-expand {
        width: 100%;
        margin-left: 180px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .side-nav-open {
        width: 0;
        position: fixed;
        top: 0;
        left: 0;
        overflow-x: hidden;
    }
    .side-nav-close {
        width: 180px;
        position: fixed;
        top: 0;
        left: 0;
        overflow-x: hidden;
    }
    .content {
        width: 100%;
        margin-left: 0;
    }
    .content-expand {
        width: 100%;
        margin-left: 180px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .side-nav-open {
        width: 0;
        position: fixed;
        top: 0;
        left: 0;
        overflow-x: hidden;
    }
    .side-nav-close {
        width: 180px;
        position: fixed;
        top: 0;
        left: 0;
        overflow-x: hidden;
    }
    .content {
        width: 100%;
        margin-left: 0;
    }
    .content-expand {
        width: 100%;
        margin-left: 180px;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {

}

@media (min-width: 1200px) {

}
